import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Sensors from 'sa-sdk-javascript';

Sensors.init({
  name: 'sensors',
  server_url: 'https://bi-log.aihuishou.com/trace/log',
  web_url: '',
  show_log: true,
  heatmap: {
    clickmap: 'default',
    scroll_notice_map: 'not_collect',
  },
  send_type: 'image',
  callback_timeout: 100,
  use_client_time: true,
  batch_send: false,
  is_track_single_page: false
});
Sensors.registerPage({
  platform: 'web_pc',
  app_channel: '',
  app_name: 'official'
});
Sensors.quick('autoTrack');


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
