import React from "react"
import "./App.css"


function App() {

  return (
    <div className="page">
      <div className="head">
        <img src={require('./assets/images/head.png')} />
      </div>
      <div className="floor floor1">
        <img className="title" src={require('./assets/images/floor1-title.png')} />
        <img className="con" src={require('./assets/images/floor1-con.png')} />
      </div>
      <div className="floor floor2">
        <img className="title" src={require('./assets/images/floor2-title.png')} />
        <img className="con" src={require('./assets/images/floor2-con.png')} />
      </div>
      <div className="floor floor3">
        <img className="title" src={require('./assets/images/floor3-title.png')} />
        <img className="con" src={require('./assets/images/floor3-con.png')} />
      </div>
      <div className="footer">
        Copyright 2024-2034 上海红创网络科技有限公司 |&nbsp;<a href="https://beian.miit.gov.cn">沪ICP备2024063285号-1</a>
      </div>
    </div>
  )
}

export default App
